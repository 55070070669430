<template>
  <div id="app">
    <div class="usermenu">
      <a
        href="#"
        class="link"
        v-if="userAdmin()"
        @mousedown.prevent="goTo('/Admin')"
        >Admin</a
      >
<!--       <a
        href="#"
        class="link"
        v-if="loggedIn() && inProject() && !loggedIn().customData.admin"
        @mousedown.prevent="selectProject"
        >Restart simulation</a
      > -->

      <a href="#" class="link" v-if="loggedIn()" @mousedown.prevent="logOut"
        >Sign out</a
      >
    </div>

    <div id="main" v-if="!isAdminPage()">
      <router-view></router-view>
    </div>
    <div id="admin-content" :class="adminClass" v-if="isAdminPage()">
      <admin-header></admin-header>
      <div>
        <router-view></router-view>
      </div>
      <funding></funding>
    </div>
  </div>
</template>

<script>
import { currentUser } from "./store";
import "vue-select/dist/vue-select.css";
import AdminHeader from "./components/AdminHeader";
import Funding from "./components/Funding";

export default {
  name: "app",
  components: { AdminHeader, Funding },
  computed: {
    adminClass() {
      return this.$route.path.indexOf("Admin/Scenario") > -1
        ? "scenario-admin"
        : "project-admin";
    },
  },
  methods: {
    loggedIn() {
      const user = currentUser();
      return user && user._profile.identities[0].providerType != "anon-user"
        ? user
        : false;
    },
    userAdmin() {
      const user = this.loggedIn();
      return user && (user.customData.admin ||
        (user.customData.editorForProjects && user.customData.editorForProjects.length > 0))
    },
    inProject() {
      return !!this.$store.state.currentProject;
    },
    logOut: function () {
      const startPage = (this.$store.state.code) ? this.$store.state.code : "StartPage"
      this.$store.dispatch("logOut").then(() => {
        this.goTo(`/${startPage}`);
      });
    },
    selectProject: function () {
      this.$store.commit("setCurrentProject", null);
      this.goTo("/SelectProject");
    },
    startOver() {
      this.$store.commit("setSolution", null);
      this.$store.commit("setScenario", null);
      this.goTo("/SelectScenario");
    },
    goTo(path) {
      try {
        this.$router.push({
          path: path,
        });
      } catch (e) {
        console.log(e);
      }
    },
    backToGame() {
      this.$store
        .dispatch("reloadProject")
        .then(() => {
          this.goTo(
            this.$store.state.currentPage
              ? this.$store.state.currentPage
              : "/SelectScenario"
          );
        })
        .catch((e) => {
          console.log(e);
        });
    },
    isAdminPage() {
      return (
        this.userAdmin() &&
        this.$route.path.indexOf("Admin") !== -1
      );
    },
  },
};
</script>

<style lang="scss">
@import "./styles/app.scss";

#app {
}

#error,
#info {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  color: white;
  right: 0;
  margin: auto;
  width: 300px;
  text-align: center;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
}

#info {
  z-index: 1003;
}

#error {
  background: red;
  z-index: 1004;
}

.usermenu {
  position: absolute;
  top: 20px;
  right: 105px;
  z-index: 100;
  display: flex;
}

.usermenu.left {
  right: initial;
  left: 105px;
}
.usermenu a {
  text-align: center;
  flex-basis: 30%;
  text-decoration: underline;
  min-width: 60px;
  font-weight: 500;
}

#app #content {
  padding: 20px 0;
  background: rgba(255, 255, 255, 0.7);
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  position: relative;
  overflow: hidden;
}
</style>
