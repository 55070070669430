import * as Colyseus from "colyseus.js"
import Vue from "vue";
import {featureCollection, feature } from "@turf/turf"

const decodeImplementations = function(state) {
  let features = []
  for (const [index, value] of state.implementations.features.$items) {
    let geometry = {
      "type": value.geometry.type
    }
    if (value.geometry.type == "LineString") {
      geometry.coordinates = []
      value.geometry.shapes[0].coordinates.forEach(c => {
        geometry.coordinates.push([c.lat, c.lng])
      })
    } else if (value.geometry.type == "Point") {
        geometry.coordinates = [
          value.geometry.shapes[0].coordinates[0].lat,
          value.geometry.shapes[0].coordinates[0].lng
        ]
    } else if (value.geometry.type == "Polygon") {
      geometry.coordinates = [[]]
      value.geometry.shapes.forEach(shape => {
        shape.coordinates.forEach(c => {
          geometry.coordinates[0].push([c.lat, c.lng])
        })
      })
    } else {
      continue;
    }

    let f = feature(geometry)
    f.properties.timeline = value.timeline
    f.properties.id = value.id
    f.properties.objectType = value.objectType
    features.push(f)
  }

  return featureCollection(features);
}

const Game = {
  install: function(Vue, options) {
   // const gameState = Vue.observable({ state: {} })
    const client = new Colyseus.Client((process.env.VUE_APP_COLYSEUS_URL || 'ws://localhost:2567'))
    Vue.prototype.$game = {
      state: Vue.observable({
        implementations: { "type": "featureCollection", "features": []},
        connected: false
      }),
      client: client,
      createRoom: async function(options) {
        const room = await client.create("explorer_game", options)
        return room
      },
      addRoomHandlers: function() {
        this.room.onStateChange(state => {
          this.state.implementations = decodeImplementations(state)
          console.log(this.state)
        })

        this.room.onJoin(state =>{
          this.state = state
                  console.log(state)
        })
        this.room.onLeave((code) => {
          this.state.connected = false
        });
        this.room.onError((error) => {
          console.error(error);
        });
      },
      joinOrCreateRoom: async function(roomId, createOptions) {
        try {
          return await this.joinRoom(roomId)
        } catch(e) {
          if (e.toString().match(/room ".+" not found/)) {
            try {
              this.room = await this.createRoom(createOptions)
              console.log(this.room)
              if (this.room.hasJoined) {
                this.state.connected = true
                this.addRoomHandlers()
              }
              return this.room.id //await this.joinRoom(this.room.id)
            } catch(e) {
              console.log(e)
              throw e
            }
          } else {
            throw e
          }
        }
      },
      reconnect: async function(roomId) {
        console.log("Reconnecting room: " + roomId);
          try {
            this.room = await client.reconnect(this.room.reconnectionToken);
            this.state.connected = true;

            //this.addRoomHandlers()

            return this.room.id
          } catch (e) {
            throw e
          }
      },
      joinRoom: async function(roomId) {
        console.log("Joining room: " + roomId);
          try {
            this.room = await client.joinById(roomId);
            this.state.connected = true;

            this.addRoomHandlers()

            return this.room.id
          } catch (e) {
            throw e
          }
      },
      setImplementation: async function(data) {
        this.room.send("setImplementation", data)
      },
      finishGame: async function(data = {}) {
        this.room.send("finishGame", data)
      }
    }
    // configure the app
  }
}

export default Game;